
import Vue from "vue";
import Component from "vue-class-component";
import { mapGetters } from "vuex";
import RestaurantFoodList from "@/components/lists/RestaurantFoodList.vue";
import RestaurantHoursList from "@/components/lists/RestaurantHoursList.vue";
import AutoComplete from "@/components/AutoComplete.vue";
import ImageUpload from "@/components/dialogs/ImageUpload.vue";

// import { /*Convert,*/ HourTest } from "@/entities/restaurant/HoursTest";
import PdfUpload from "@/components/dialogs/PdfUpload.vue";

import { langs, deplacements } from "@/constants";
import { Image } from "@/entities/images/Image";
import { Text } from "@/entities/Text";
import { Location } from "@/entities/Location";
import { Restaurant } from "@/entities/restaurant/Restaurant";
import { Food } from "@/entities/restaurant/Food";
import { Hour, NewHour } from "@/entities/restaurant/Hour";
import { PdfMenu } from "@/entities/pdfMenu/PdfMenu";
import { Deplacement } from "@/entities/Deplacement";
import ConfirmationDialog from "@/components/dialogs/Confirm.vue";
import { Ref } from "vue-property-decorator";
import BusinessHours from '@/components/businessHours/BusinessHours.vue';
import { Day } from "@/entities/restaurant/Day";
@Component({
  computed: mapGetters(["location"]),

  data() {
    return {
      avoidLeaveConfirmation: false,
    }
  },

  components: {
    RestaurantFoodList,
    RestaurantHoursList,
    PdfUpload,
    AutoComplete,
    ImageUpload,
    ConfirmationDialog,
    BusinessHours
  },
  beforeRouteLeave(to, from, next) {
    if (this.$data.avoidLeaveConfirmation == true) {
      next();
    } else {
      const answer = window.confirm(
        "Are you sure you want to leave this page? All unsaved changes will be lost."
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    }
  },
})
export default class SnacksCreateView extends Vue {
  @Ref("confirm") readonly confirm!: ConfirmationDialog;
  @Ref("hoursList") readonly hoursList!: BusinessHours;

  days: Day[] = [
    {
      day: 'Lundi',
      open: false,
      hours: [
        {
          open: '',
          close: '',
        } as NewHour,
      ] as NewHour[],
      alwaysOpen: false,
    } as Day,
    {
      day: 'Mardi',
      open: false,
      hours: [
        {
          open: '',
          close: '',
        } as NewHour,
      ] as NewHour[],
      alwaysOpen: false,
    } as Day,
    {
      day: 'Mercredi',
      open: false,
      hours: [
        {
          open: '',
          close: '',
        } as NewHour,
      ] as NewHour[],
      alwaysOpen: false,
    } as Day,
    {
      day: 'Jeudi',
      open: false,
      hours: [
        {
          open: '',
          close: '',
        } as NewHour,
      ] as NewHour[],
      alwaysOpen: false,
    } as Day,
    {
      day: 'Vendredi',
      open: false,
      hours: [
        {
          open: '',
          close: '',
        } as NewHour,
      ] as NewHour[],
      alwaysOpen: false,
    } as Day,
    {
      day: 'Samedi',
      open: false,
      hours: [
        {
          open: '',
          close: '',
        } as NewHour,
      ] as NewHour[],
      alwaysOpen: false,
    } as Day,
    {
      day: 'Dimanche',
      open: false,
      hours: [
        {
          open: '',
          close: '',
        } as NewHour,
      ] as NewHour[],
      alwaysOpen: false,
    } as Day,

  ];

  //#region Variables
  location!: Location;
  deplacements = deplacements;
  transport = deplacements[0].abbr;
  textColor: string = "";
  editedItem = {
    themeColor: "#41B773",
  };
  myColors = {
        Color: "#FFFFFF",
  }

  hoursKey=0;
  dialog = false;
  restaurant = {
    rating: 0.0 as number,
    subtitle: { en: "", fr: "" } as Text,
    textColor: {},
    description: {} as Text,
    additional: { en: "", fr: "" } as Text,
    food: [] as Food[],
    images: [] as Image[],
    type: "hotel",
    priceTag: null as unknown as number,
    hasParking: false as boolean,
    restaurantHotel: false as boolean,
    // openingHours: [] as Hour[],
    // openingHours: "",
    logoImage: {} as Image,
    cdcIndex: 0 as number,
    proximIndex: 0 as number,
    lat: 0.1 as number,
    lng: 0.1 as number,
    menu: {} as PdfMenu,
    deplacements: [] as Deplacement[],
    defaultTransport: "walking",
    isHidden: false as boolean,
    address: "",


    openingHours: [
      {
        id: 1,
        day: "Lundi",
        open: "",
        close: "",
        alwaysOpen: false,
        closed: false,
      },
      {
        id: 2,
        day: "Mardi",
        open: "",
        close: "",
        alwaysOpen: false,
        closed: false,
      },
      {
        id: 3,
        day: "Mercredi",
        open: "",
        close: "",
        alwaysOpen: false,
        closed: false,
      },
      {
        id: 4,
        day: "Jeudi",
        open: "",
        close: "",
        alwaysOpen: false,
        closed: false,
      },
      {
        id: 5,
        day: "Vendredi",
        open: "",
        close: "",
        alwaysOpen: false,
        closed: false,
      },
      {
        id: 6,
        day: "Samedi",
        open: "",
        close: "",
        alwaysOpen: false,
        closed: false,
      },
      {
        id: 7,
        day: "Dimanche",
        open: "",
        close: "",
        alwaysOpen: false,
        closed: false,
      },
    ] as Hour[],
    noHours: false,
    businessHours: this.days,
    placeId: "",
  } as unknown as Restaurant;
  subtitleFR = "";
  subtitleEN = "";
  valid = false;
  langs = langs;
  lang = langs[0].abbr;
  dialogPdf = false;
  search = "";
  searchLogo = "";
  searchMenu = "";
  images: Image[] = [];
  logoImages: Image[] = [];
  fab = false;
  uploadCategory = "";

  prices = [
    { text: "", abbr: null },
    { text: "$", abbr: 1 },
    { text: "$$", abbr: 2 },
    { text: "$$$", abbr: 3 },
    { text: "$$$$", abbr: 4 },
  ];
  menus: PdfMenu[] = [];
  selectedLogoIndex = 0;
  selectedBannerIndex = [0];
  selectedMenuIndex: number|null = null;
  logoAlert = false;
  bannerAlert = false;
  menuAlert = false;
  //#endregion

  //#region Rules
  nameRules = [(v: string) => !!v || "Name is required"];
  positionRules = [
    (v: number) => {
      if (!(v.toString().includes(".") || v.toString().includes(","))) {
        return true;
      } else {
        return "Only whole number accepted";
      }
    },
    (v: number) => {
      if (v >= -1 && v <= 1) {
        return true;
      } else {
        return "Minimum is -1 and maximum is 1";
      }
    },
    (v: number) => {
      if (v || (v !== undefined && v.toString() !== "")) {
        return true;
      } else {
        return "Position is required";
      }
    },
  ];
  phoneRules = [
    (v: string) => !!v || "Phone is required",
    (v: string) => {
      if (/^(\d{3}[-]?){1,2}(\d{4})$/.test(v)) {
        return true;
      } else {
        return "Phone number is invalid";
      }
    },
  ];
  ratingRules = [
    (v: number) => {
      if(v==null){
        return true;
      }
      if (v >= 0 && v <= 5 && v?.toString().length <= 3) {
        return true;
      } else {
        return "Rating is lower than 0 or higher than 5";
      }
    },
  ];
  //#endregion

  //#region Methods
  createDeplacements(deplacement: typeof deplacements): Deplacement[] {
    let deplacements: Deplacement[] = [];
    deplacement.forEach((element) => {
      let temp: Deplacement = {
        type: element.abbr,
        estimate: "",
      };
      deplacements.push(temp);
    });
    return deplacements;
  }
  async fetchAll() {
    await this.fetchImagesAsync();
    await this.fetchMenus();
    await this.fetchRestaurant();
  }

  openImageDialog(category: string) {
    this.uploadCategory = category;
    this.dialog = true;
  }

  getAddressData(addressData: any, placeResultData: any) {
    this.restaurant.address = placeResultData.formatted_address;
    if (
      placeResultData.place_id &&
      placeResultData.place_id != null &&
      placeResultData.place_id != undefined &&
      placeResultData.place_id != ""
    ) {
      this.restaurant.placeId = placeResultData.place_id;
    }
    if (addressData.latitude && addressData.longitude) {
      this.restaurant.lat = addressData.latitude;
      this.restaurant.lng = addressData.longitude;
    }
  }

  async mounted() {
    if (this.$route.name === "restaurants-edit" && this.restaurantId === null) {
      this.$data.avoidLeaveConfirmation = true;
      this.$router.replace("/snacks");
    }

    await this.fetchAll()
  }

  async fetchRestaurant() {
    if (this.restaurantId !== null) {
      this.$restaurants.getById(this.restaurantId).then((restaurant) => {
        var cloneResto = {} as Restaurant;
        cloneResto = restaurant;
        cloneResto.openingHours = restaurant.openingHours;
        if(restaurant.businessHours==null){
          cloneResto.businessHours = this.days;
        }
        else{
          cloneResto.businessHours = restaurant.businessHours;
        }

        cloneResto.subtitle = restaurant.subtitle;
        this.restaurant = cloneResto;
        this.restaurant.deplacements = restaurant.deplacements;
        this.restaurant.defaultTransport = restaurant.defaultTransport;
        this.restaurant.isHidden = restaurant.isHidden;
        this.restaurant.textColor = restaurant.textColor;
        this.myColors.Color = restaurant.textColor;
        //console.log("Test");
        //console.log(restaurant);
        //console.log( this.restaurant.textColor);
        this.selectedLogoIndex = this.getSelectedLogoImagePos;
        this.selectedBannerIndex = this.getSelectedBannerImagePos;
        this.imageLogo = this.filteredLogos[this.selectedLogoIndex];
        let images = [] as Image[];

        this.selectedBannerIndex.forEach((element) => {
          images.push(this.BannerImages[element]);
        });

        this.selectedMenuIndex = this.allMenus.findIndex(
          (x) => x.id == restaurant.menu?.id
        );

        this.subtitleFR = restaurant.subtitle.fr;
        this.subtitleEN = restaurant.subtitle.en;
      }).then(()=>{
        this.hoursKey++;
      });
    } else {
      this.selectedLogoIndex = this.getSelectedLogoImagePos;
      this.selectedBannerIndex = this.getSelectedBannerImagePos;
      this.imageLogo = this.$helpers.filterImages(
        this.logoImages,
        this.searchLogo,
        ["Logo"]
      )[this.selectedLogoIndex];
      let images = [] as Image[];

      this.selectedBannerIndex.forEach((element) => {
        images.push(this.filteredBanner[element]);
      });
      images = images.reverse();
      images.push(this.imageLogo);
      images = images.reverse();
      this.restaurant.images = images;
      this.restaurant.deplacements = this.createDeplacements(this.deplacements);
      this.restaurant.defaultTransport = "walking";
    }
  }

  async fetchImages() {
    this.$images.list().then((images) => {
      this.images = images;
      this.logoImages = images;
      //console.log("fetchImage Done");
      //console.log(this.logoImages);
    });
  }
  async fetchImagesAsync() {
    this.images = await this.$images.list();
    this.logoImages = await this.$images.list();
    //console.log("fetchImage Done");
    //console.log(this.logoImages);
  }

  validateAll() {
    var restoValid = this.form.validate();

    var hourValid = this.hoursList.validate();
    // var hourValid = this.hoursForm.validate();
    var addressValid = this.addressForm.validate();
    // var menuValid = false;
    var logoValid = false;
    var BannerValid = false;
    if (this.filteredLogos.length > 0) {
      logoValid = true;
      this.logoAlert = false;
    } else {
      this.logoAlert = true;
    }
    if (this.BannerImages.length > 0) {
      BannerValid = true;
      this.bannerAlert = false;
    } else {
      this.bannerAlert = true;
    }
    if (
      restoValid &&
      hourValid &&
      addressValid &&
      BannerValid &&
      logoValid /* &&
      menuValid */
    ) {
      return true;
    } else {
      return false;
    }
  }
  logEvent(e:any){
    console.log(e)
  }

  save() {
    if (this.validateAll()) {
      var cloneResto = this.restaurant;
      // let test = {} as Hour[];
      let hours = {} as Day[];
      cloneResto.textColor = this.myColors.Color;
      cloneResto.menu = this.selectedMenu;
      hours = this.heure!;
      if (this.restaurant.restaurantHotel) {
        cloneResto.address = "";
        this.Address = "";
      }
      // cloneResto.openingHours = test;
      cloneResto.businessHours = hours;
      if (this.restaurantId !== null) {
        this.$restaurants.edit(
          this.restaurant,
          this.$restaurants.toData(this.restaurant)
        );
        return;
      }
      this.$restaurants
        .create(this.$restaurants.toData(cloneResto, this.location.uuid))
        .then(() => {
          this.$data.avoidLeaveConfirmation = true;
          this.$router.replace("/restaurants");
        });
    }
  }
  deleteResto() {
    this.confirm
      .open("Delete", "Are you sure?", { color: "red" })
      .then((answer) => {
        if (answer) {
          //console.log("Delete");
          this.$restaurants.delete(this.restaurant).then(() => {
            this.$data.avoidLeaveConfirmation = true;
            this.$router.replace("/restaurants");
          })
        }
      });
  }

  bannerImageClicked(image: Image) {
    //console.log(image);
    //console.log("Before: ");
    //console.log(this.BannerImages);

    if (this.BannerImages.some((i) => i.id == image.id)) {
      this.removeBannerImage(image);
    } else {
      this.addBannerImage(image);
    }
    //console.log("After: ");
    //console.log(this.BannerImages);
  }

  addBannerImage(image: Image) {
    if (!this.selectedImages.some((i) => i.id === image.id)) {
      this.BannerImages.push(image);
      this.selectedImages.push(image);
    }
  }
  addLogoImage(image: Image) {
    //console.log("logoImg: ");
    //console.log(this.restaurant.tileImage);
    if (!this.selectedImages.some((i) => i.id === image.id)) {
      this.restaurant.tileImage = image;
      this.selectedImages.splice(
        this.selectedImages.findIndex((i) => i.id === this.LogoImages[0].id),
        1
      );
      this.selectedImages.reverse();
      this.selectedImages.push(image);
      this.selectedImages.reverse();
    }
  }

  removeBannerImage(image: Image) {
    if (this.getSelectedBannerImagePos.length > 0) {
      //console.log("banner before: ");
      //console.log(this.restaurant.images);

      this.selectedImages.splice(
        this.selectedImages.findIndex((i) => i.id === image.id),
        1
      );
      this.selectedBannerIndex.splice(
        this.BannerImages.findIndex((i) => i.id === image.id),
        1
      );

      //console.log("banner after: ");
      //console.log(this.restaurant.images);
    }
  }

  async fetchMenus() {
    this.$pdfMenus.list().then((menus) => {
      this.menus = menus;
    });
  }

  //#endregion

  //#region Get/Set
  get Transport() {
    return this.transport;
  }
  set Transport(value) {
    this.transport = value;
  }

  get Address() {
    return this.restaurant.address;
  }
  set Address(value) {
    this.restaurant.address = value;
  }
  get deplacement() {
    return this.restaurant.deplacements?.find((i) => i.type == this.Transport)!;
  }
  set deplacement(value) {
    let index = this.restaurant.deplacements!.indexOf(this.deplacement!);
    this.restaurant.deplacements![index] = value!;
  }

  get defaultTransport() {
    return this.restaurant.defaultTransport;
  }
  set defaultTransport(value) {
    this.restaurant.defaultTransport = value;
  }

  get deplacementText() {
    return this.deplacements.find((i) => i.abbr == this.Transport)!.text;
  }

  get estimate() {
    return this.deplacement?.estimate;
  }

  set estimate(value) {
    this.deplacement.estimate = value;
  }

  get restaurantId() {
    if (this.$route.params.restaurant !== undefined) {
      return Number(this.$route.params.restaurant);
    }
    return null;
  }
  get form() {
    return this.$refs.form as Vue & { validate: () => boolean };
  }
  // get hoursForm() {
  //   let hoursList = this.$refs.hoursList as BusinessHours;
  //   return hoursList;
  //   // return hoursList.$refs.formHours as Vue & { validate: () => boolean };
  // }
  get addressForm() {
    let hoursList = this.$refs.address as Vue & { validate: () => boolean };
    return hoursList.$refs.autocomplete as Vue & { validate: () => boolean };
  }

  get imageShown(): Image[] {
    return (
      this.images?.filter(
        (image) => image.category == "Logo" || image.category == "Banner"
      ) ?? ([] as Image[])
    );
  }

  get selectedImages(): Image[] {
    return this.restaurant.images ?? ([] as Image[]);
  }
  get LogoImages(): Image[] {
    return (
      this.restaurant.images?.filter((image?) => image?.category == "Logo") ??
      ([] as Image[])
    );
  }
  get BannerImages(): Image[] {
    return (
      this.restaurant.images?.filter((image?) => image?.category == "Banner") ??
      ([] as Image[])
    );
  }
  get getSelectedLogoImagePos(): number {
    if (this.selectedImages && this.selectedImages.length > 0) {
      let imageCherchee = this.LogoImages[0];
      let index = 0;
      let listeImagesShown = this.imageShown;
      let listeImagesLogoShown =
        listeImagesShown?.filter((image) => image.category == "Logo") ??
        ([] as Image[]);

      index = listeImagesLogoShown.findIndex((i) => i.id == imageCherchee.id);

      return index;
    } else {
      return 0;
    }
  }

  get getSelectedBannerImagePos(): number[] {
    if (this.selectedImages && this.selectedImages.length > 0) {
      let index = [] as number[];
      this.BannerImages.forEach((element) => {
        let listeImagesShown = this.imageShown;
        let listeImagesBannerShown =
          listeImagesShown?.filter((image) => image.category != "Logo") ??
          ([] as Image[]);

        index.push(listeImagesBannerShown.findIndex((i) => i.id == element.id));
      });
      return index;
    } else {
      //console.log("no images");
      return [];
    }
  }

  set getSelectedBannerImagePos(value) {}

  get allMenus(): PdfMenu[] {
    return this.menus;
  }
  get selectedMenu(): PdfMenu | null {
    //console.log(this.selectedMenuIndex);
    if (this.selectedMenuIndex == null) return null;
    let menuIndex = this.selectedMenuIndex;
    let allMenus = this.allMenus;
    return allMenus[menuIndex];
  }

  get filteredBanner() {
    return this.$helpers.filterImages(this.images, this.search, ["Banner"]);
  }
  get filteredLogos() {
    return this.$helpers.filterImages(this.logoImages, this.searchLogo, [
      "Logo",
    ]);
  }
  get filteredMenus(){
    return this.$helpers.filterMenus(this.menus, this.searchMenu);
  }

  get subtitle() {
    return this.restaurant.subtitle[this.lang];
  }

  set subtitle(value) {
    this.restaurant.subtitle[this.lang] = value;
  }
  get priceTag() {
    return this.restaurant.priceTag;
  }

  set priceTag(value) {
    this.restaurant.priceTag = value;
  }

  set heure(value1) {
    this.restaurant.businessHours = value1;
  }

  get heure() {
    return this.restaurant.businessHours;
  }
  // set heure(value1) {
  //   this.restaurant.openingHours = value1;
  // }

  // get heure() {
  //   return this.restaurant.openingHours;
  // }

  get description() {
    return this.restaurant.description[this.lang];
  }

  set description(value) {
    this.restaurant.description[this.lang] = value;
  }
  get restoHotel() {
    return this.restaurant.restaurantHotel;
  }

  set restoHotel(value) {
    this.restaurant.restaurantHotel = value;
  }
  get additional() {
    return this.restaurant.additional[this.lang];
  }

  set additional(value) {
    this.restaurant.additional[this.lang] = value;
  }

  get imageLogo() {
    return this.restaurant.tileImage;
  }

  set imageLogo(value) {
    this.restaurant.tileImage = value;
  }
  //#endregion

}
