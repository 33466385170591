export class Hour {
  id!: number;
  open!: string;
  close!: string;
  day!: string;
  alwaysOpen!: boolean;
  closed!: boolean;
}
export class NewHour {
  id!: number;
  open!: string;
  close!: string;

  constructor() {
    this.open = "";
    this.close = "";
  }
}
