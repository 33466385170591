
import Vue from "vue";
import Component from "vue-class-component";

import BusinessDay from "@/components/businessHours/BusinessDay.vue";
import { Day } from "@/entities/restaurant/Day";
import { Prop } from "vue-property-decorator";
@Component({
  components: {
    BusinessDay,
  },
})
export default class BusinessHours extends Vue {
  // #region Props
  @Prop()
  value!: Day[];
  @Prop({ required: true })
  noHours!: boolean;
  // #endregion

  // #region Data
  showHours = true;
  val: Day[] = this.value;


  // #endregion

  // #region Methods

  mounted(){
    // console.log("mounted");
  }

  onDisableHours() {
    if(!this.disabledHours){
      this.val.forEach((day) => {
        day.hours.splice(1);
        day.hours[0].open = day.hours[0].close = "";
        day.open = false;
        day.alwaysOpen = false;
      });
    }
  }

  validate(){
    if(this.disabledHours) return true;

    let asErrors: boolean[] = [];
    let refs = this.$refs.dayRefs as BusinessDay[];
    refs.forEach((day) => {
      let dayComp = day as BusinessDay;
      asErrors.push(dayComp.manualValidation());
    });
    return !asErrors.includes(true);
  }

  totalInputs(day: Day) {
    return day.hours.length * 2;
  }

  hoursChanged(val: Day) {
    // console.log("change")
    this.val.find((day) => day.day === val.day)!.hours = val.hours;
    this.updateValue();
  }

  updateValue() {
    // console.log("updateValue");
    this.$emit("input", this.val);
  }
  // #endregion

  // #region Getters and Setters
  get disabledHours() {
    return this.noHours;
  }
  set disabledHours(value1) {
    this.$emit("update:noHours", value1);
  }
  // #endregion
}
