
import Vue from "vue";
import Component from "vue-class-component";

import { Prop, Watch } from "vue-property-decorator";
@Component({
  components: {}
})
export default class BusinessHourInput extends Vue {
  @Prop()
  value!: string;

  @Prop()
  onValueChanged!: (value: any) => void;

  @Prop()
  label!: string;
  @Prop()
  anyErrors!: boolean;

  @Prop()
  selectedTime!: string;

  val: string = this.value;

  updateValue() {
    // console.log(this.val, this.value);
    this.$emit("input", this.val);
  }
  times: any[] = [];

  selected = this.selectedTime;
  @Watch("selectedTime")
  selectedTimeChanged() {
    this.selected = this.selectedTime;
  }
  inputEventHandler(e: any) {
    this.$emit("input-change", e.target.value);
  }
}
