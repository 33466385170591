
import { Day } from "@/entities/restaurant/Day";
import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { ToggleButton } from "vue-js-toggle-button";
import { NewHour } from "@/entities/restaurant/Hour";
import { BusinessValidator } from "./businessValidator";
import BusinessHourInput from "./BusinessHourInput.vue";
import HourHelper from "./hourHelper";

@Component({
  components: {
    ToggleButton,
    BusinessHourInput
  },
  mixins: [BusinessValidator]
})
export default class BusinessHoursDay extends mixins(BusinessValidator) {
// #region Props
  @Prop({ required: true })
  day!: Day;

  @Prop({})
  width!: number;

  @Prop({ required: true })
  name!: string;

// #endregion

// #region Data
  validations: any[] = [];
// #endregion

// #region Methods

  showDay(index: number) {
    return index > 0 ? false : true;
  }
  showAdd(index: number) {
    return (
      this.hours.length === index + 1 &&
      this.hours[index].open !== "" &&
      this.hours[index].close !== ""
    );
  }
  showRemove() {
    return this.day.hours.length > 1 ? true : false;
  }

  toggleOpen() {
    this.day.open = !this.day.open;
    this.day.alwaysOpen = false;
  }

  addHour() {
    this.day.hours.push(new NewHour());
    this.updateHours();
    this.runValidations();
  }
  removeHour(index: number) {
    this.day.hours.splice(index, 1);
    this.updateHours();
    this.runValidations();
  }
  alwaysOpenChanged() {
    this.day.hours.splice(1);
    this.hours[0].open = "00:00";
    this.hours[0].close = "23:59";
    this.updateHours();
    this.runValidations();
  }

  manualValidation(){
    if(!this.day.open) return false;
    this.runValidations();
    // console.log(this.validations.some(validation => validation.anyErrors));
    return this.validations.some(validation => validation.anyErrors);
  }

  resetHours() {
    this.day.hours.splice(1);
    this.hours[0].open = this.hours[0].close = "";
    this.updateHours();
  }
  onChangeEventHandler(
    whichTime: "open" | "close",
    index: number,
    value: string
  ) {
    if (this.day.alwaysOpen) {
      this.hours.splice(1);
      this.hours[0].open = this.hours[0].close = value;
      this.runValidations();
      this.updateHours();
      return;
    }
    if (
      !HourHelper.onlyOneRow(this.hours) &&
      value === "" &&
      ((whichTime === "open" && this.hours[index].close === "") ||
        (whichTime === "close" && this.hours[index].open === ""))
    ) {
      this.removeHour(index);
      this.runValidations();
      this.updateHours();
      return;
    }

    this.hours[index][whichTime] = value;
    this.runValidations();
    this.updateHours();
  }

  inputNumber(whichTime: "open" | "close", index: number) {
    if (whichTime === "open") {
      return index * 2 + 1;
    } else if (whichTime === "close") {
      return index * 2 + 2;
    }
  }

  updateHours() {
    this.totalInputsData = this.hours.length * 2;
    this.$emit("hours-change", this.day);
  }
// #endregion
}
