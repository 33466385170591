
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, /* Watch */ } from 'vue-property-decorator';

import { langs, foodCategories, foodTypes } from '@/constants';
import { Food } from '@/entities/restaurant/Food';
import { Text } from '@/entities/Text';
import { Image } from '@/entities/images/Image';

@Component
export default class FoodDialog extends Vue {
  @Prop({ required: true })
  value!: boolean;

  @Prop({ required: true })
  add!: Function;
  @Prop({ default: false })
  imgAlert?:boolean;

  // foodItem:Food = {}
  // foodItem? = {
  //   name: {

  //   } as Text
  // } as Food;
  foodItem: Food = {
    name: {

    } as Text,
    price: {} as number,
    type: {} as string,
    category: {} as string,
    image: {} as Image,

  } as Food;

  langs = langs;
  lang = langs[0].abbr;
  
  categories = foodCategories;
  types = foodTypes;

  search = "";
  images: Image[] = [];

  get foodDial() {
    let foodForm = this.$refs.foodForm as Vue & { validate: () => boolean, resetValidation: () => {}  };
    return foodForm;
  }
  selectedImage = 0;
  // @Watch('show')
  // onPersonChanged1(/* val: boolean, oldVal: boolean */) {
  // this.foodDial.resetValidation();
  // }

  validateForm(food: Food) {
    if (!(food.image!)) {
      this.imgAlert=true;
    }
    var restoValid = (this.foodDial.validate()&&!!(food.image!))
    return restoValid;
  }

  save(item:Food) {
    if (this.validateForm(item))
    {
      this.add(item)
      this.show=false;
      this.imgAlert=false;
    }
    else
    {
      this.show=true;
      // this.imgAlert=true;

    }
  }

  //rules

  nameRules = [
      (v: Text) => !!v || 'Name is required',
      () => {
        if (!!this.foodItem?.name.fr && !!this.foodItem?.name.en) {
          return true;
        } else {
           return 'Name is required in FR and EN';
        }
      }
  ];
  categoryRules = [
      (v: string) => !!v || 'Category is required',
      (v: string) => {
        // console.log("LANG: ");
        // console.log(this.restaurant.subtitle);
        // console.log("Resto: ");
        // console.log(this.restaurant);
        if (foodCategories.includes(v)) {
          return true;
        } else {
           return 'Category is required';
        }
      }
  ];
  typeRules = [
      (v: string) => !!v || 'Type is required',
      (v: string) => {
        if (foodTypes.includes(v)) {
          return true;
        } else {
           return 'Type is required';
        }
      }
  ];
  imgRules=[
    (v:string) => {
      // console.log("V: " + !!v);
      // console.log("checked: " + !!this.restaurant.restaurantHotel);

    if (v) {
      return true;
    }
    else {
      return 'Address is required'
    }
  }
  ];
  priceRules = [
      (v: number) => {
        if (v) {
          return true;
        } else {
          return 'Price is required'
        }
      },
      (v: number) => {
        if (!!v && v.toString().length<=5) {
          return true;
        } else {
          return 'Price is too long (max: 5 numbers)'
        }
      },
  ];

  async mounted() {
  this.foodItem = {
    name: {

    } as Text
  } as Food;
    await this.fetchImages();
      this.image=this.images[this.selectedImage];
  }

  async fetchImages() {
    // this.$images.list().then(images => {
    //   this.images = images;
    // });
    this.images = await this.$images.list()
  }

  get filtered() {
    return this.$helpers.filterImages(this.images, this.search, ["Food"]);
  }

  get name() {
    return this.foodItem?.name[this.lang];
  }

  set name(value) {
    this.foodItem!.name[this.lang] = value;
  }
  get image() {
    return this.foodItem?.image;
  }

  set image(value) {
    this.foodItem!.image = value;
  }

  get show() {
    return this.value;
  }

  set show(value) {
    this.$emit('input', value);
  }
}
