import Component from "vue-class-component";
import Vue from "vue";
import HourHelper from "./hourHelper";
import { Prop } from "vue-property-decorator";
import { NewHour } from "@/entities/restaurant/Hour";

interface Error {
  open: ErrorMessages;
  close: ErrorMessages;
}
interface ErrorMessages {
  invalidInput: string;
  greaterThanNext: string;
  lessThanPrevious: string;
  midnightNotLast: string;
}

@Component
export class BusinessValidator extends Vue {
  validations: any[] = [];

  errors = {
    open: {
      invalidInput:
        "Please enter an opening time in the 24 hour format (ie. 18:00).",
      greaterThanNext:
        "Please enter an opening time that is before the closing time.",
      lessThanPrevious:
        "Please enter an opening time that is after the previous closing time.",
      midnightNotLast:
        "Midnight can only be selected for the day's last closing time."
    } as ErrorMessages,
    close: {
      invalidInput:
        "Please enter a closing time in the 24 hour format (ie. 22:00).",
      greaterThanNext:
        "Please enter a closing time that is after the opening time.",
      lessThanPrevious:
        "Please enter a closing time that is before the next opening time.",
      midnightNotLast:
        "Midnight can only be selected for the day's last closing time."
    } as ErrorMessages
  } as Error;

  // #region Props
  @Prop()
  hours!: NewHour[];

  @Prop()
  totalInputs!: number;
  // #endregion

  totalInputsData = this.totalInputs;

  created() {
    this.runValidations();
  }
  defaultValidation() {
    return {
      invalidInput: false,
      greaterThanNext: false,
      lessThanPrevious: false,
      midnightNotLast: false
    };
  }
  defaultValidations() {
    return {
      anyErrors: false,
      open: this.defaultValidation(),
      close: this.defaultValidation()
    };
  }
  isValidInput(input: any) {
    return (
      HourHelper.isValidBackendTime(input) || input === "00:00" /* || input === "" */
    );
  }
  resetValidations() {
    let validations: any[] = [];
    this.hours.forEach((hour, index) => {
      validations[index] = this.defaultValidations();
    });

    this.validations = validations;
  }
  runValidations() {
    let inputNum = 1;

    this.resetValidations();

    this.hours.forEach((hour, index) => {
      this.runValidation(hour.open, index, inputNum, "open");
      inputNum++;
      this.runValidation(hour.close, index, inputNum, "close");
      inputNum++;
    });

    this.updateAnyErrors();
  }
  runValidation(
    value: any,
    index: number,
    inputNum: number,
    whichTime: string
  ) {
    if (HourHelper.isValidBackendTime(value)) {
      this.validations[index][whichTime] = this.runInputValidation(
        value,
        index,
        inputNum,
        this.totalInputsData
      );
    }

    this.validations[index][whichTime].invalidInput = !this.isValidInput(value)
      ? true
      : false;

    this.updateAdjacentValidations(index, whichTime, inputNum);
  }
  runInputValidation(
    value: any,
    index: number,
    inputNum: number,
    totalInputs: number
  ) {
    const prevTime = HourHelper.getPrevious(this.hours, index, inputNum);
    const nextTime = HourHelper.getNext(
      this.hours,
      index,
      inputNum,
      totalInputs
    );
    let validations = this.defaultValidation();

    if (prevTime === undefined) {
      validations.greaterThanNext =
        value >= nextTime && nextTime !== "" && nextTime !== "00:00"
          ? true
          : false;
    } else if (nextTime === undefined) {
      validations.lessThanPrevious =
        value <= prevTime && prevTime !== "" && value !== "00:00"
          ? true
          : false;
    } else {
      validations.lessThanPrevious =
        value <= prevTime && prevTime !== "" && value !== "00:00"
          ? true
          : false;
      validations.greaterThanNext =
        value >= nextTime && nextTime !== "" && nextTime !== "00:00"
          ? true
          : false;
    }

    return validations;
  }
  updateAdjacentValidations(index: number, whichTime: string, inputNum: any) {
    const prevIndex = index - 1;
    const nextIndex = index + 1;
    const currentValidations = this.validations[index][whichTime];
    let prevValidations = HourHelper.getPrevious(
      this.validations,
      index,
      inputNum
    );
    let nextValidations = HourHelper.getNext(
      this.validations,
      index,
      inputNum,
      this.totalInputsData
    );

    validatePrevious();

    validateNext();

    if (!HourHelper.isFirstInput(inputNum) && whichTime === "open") {
      this.validations[prevIndex].close = prevValidations;
    } else if (whichTime === "close") {
      this.validations[index].open = prevValidations;
    }

    if (
      !HourHelper.isLastInput(inputNum, this.totalInputsData) &&
      whichTime === "close"
    ) {
      this.validations[nextIndex].open = nextValidations;
    } else if (whichTime === "open") {
      this.validations[index].close = nextValidations;
    }

    function validateNext() {
      if (nextValidations !== undefined) {
        if (currentValidations.greaterThanNext) {
          nextValidations.lessThanPrevious = true;
        } else if (!currentValidations.greaterThanNext) {
          nextValidations.lessThanPrevious = false;
        }
      }
    }

    function validatePrevious() {
      if (prevValidations !== undefined) {
        if (currentValidations.lessThanPrevious) {
          prevValidations.greaterThanNext = true;
        } else if (!currentValidations.lessThanPrevious) {
          prevValidations.greaterThanNext = false;
        }
      }
    }
  }
  updateAnyErrors() {
    this.validations.forEach((validation, index) => {
      return (this.validations[index].anyErrors = this.anyErrors(validation));
    });
  }
  anyErrors(validation: any) {
    return this.anyError(validation.open) || this.anyError(validation.close)
      ? true
      : false;
  }
  anyError(validation: any) {
    return Object.keys(validation).some(key => {
      return validation[key] === true;
    });
  }
  activeErrors(index: number) {
    const validations = this.validations[index];
    let errors: any[] = [];

    Object.keys(validations).forEach(key => {
      if (typeof validations[key] === "object") {
        let validation = validations[key];
        Object.keys(validation)
          .filter(key => {
            return validation[key] === true;
          })
          .forEach(error => {
            errors.push({
              whichTime: key,
              error: error
            });
          });
      }
    });

    return errors;
  }
  errorMessage(whichTime: keyof Error, error: keyof ErrorMessages) {
    return this.errors[whichTime][error];
  }
}
