import { NewHour } from "@/entities/restaurant/Hour";
import moment from "moment";

export default class HourHelper {
  static frontendTimeFormat(value: string) {
    return moment(value, "HH:mm").format("HH:mm");
  }
  static backendTimeFormat(value: string) {
    return moment(value, "HH:mm").format("HH:mm");
  }
  static isValidFrontendTime(value: string) {
    return moment(value, "HH:mm", true).isValid();
  }
  static isValidBackendTime(value: string) {
    return moment(value, "HH:mm", true).isValid();
  }
  static getPrevious(type: any, index: number, inputNum: number) {
    if (inputNum === 1) {
      return;
    }

    return this.isEven(inputNum) ? type[index]?.open : type[index - 1]?.close;
  }
  static getNext(
    type: any,
    index: number,
    inputNum: number,
    totalInputs: number
  ) {
    if (inputNum === totalInputs) {
      return;
    }

    return this.isEven(inputNum) ? type[index + 1]?.open : type[index]?.close;
  }
  static isEven(value: number) {
    return value % 2 == 0 ? true : false;
  }
  static isLastInput(inputNum: number, totalInputs: number) {
    return inputNum === totalInputs;
  }
  static isFirstInput(inputNum: number) {
    return inputNum === 1;
  }
  static onlyOneRow(hours: NewHour[]) {
    return hours.length === 1;
  }
}
