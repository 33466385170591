
/**
 * Vuetify Confirm Dialog component
 *
 * Insert component where you want to use it:
 * <confirm ref="confirm"></confirm>
 *
 * Call it:
 * this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
 * Or use await:
 * if (await this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' })) {
 *   // yes
 * }
 * else {
 *   // cancel
 * }
 *
 * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
 * <template>
 *   <v-app>
 *     ...
 *     <confirm ref="confirm"></confirm>
 *   </v-app>
 * </template>
 *
 * mounted() {
 *   this.$root.$confirm = this.$refs.confirm.open
 * }
 */
import Vue from 'vue';
import Component from 'vue-class-component';
interface Options {
      color?: string;
      width?: number;
      zIndex?: number;
}
@Component
export default class ConfirmationDialog extends Vue {
  dialog = false;
  resolve: any;
  reject: any;
  message: string = "";
  title: string = "";
  options: Options = {
      color: 'primary',
      width: 290,
      zIndex: 200
  };
  

  open(title: string, message: string, options: Options) {
      this.dialog = true
      this.title = title
      this.message = message
      this.options = Object.assign(this.options, options)
      return new Promise<boolean>((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
  }
  agree() {
      this.resolve(true)
      this.dialog = false
  }
  cancel() {
      this.resolve(false)
      this.dialog = false
  }

  get show() {
    return this.dialog
  }
  set show(value) {
    this.dialog = value
    if (value === false) this.cancel();
  }
}
