
/// <reference types="google.maps" />

import Vue from "vue";
import Component from "vue-class-component";
import { Prop, PropSync, Watch } from "vue-property-decorator";
const ADDRESS_COMPONENTS: any = {
  subpremise: "short_name",
  street_number: "short_name",
  route: "long_name",
  locality: "long_name",
  administrative_area_level_1: "short_name",
  administrative_area_level_2: "long_name",
  country: "long_name",
  postal_code: "short_name",
};


const CITIES_TYPE = ["locality", "administrative_area_level_3"];
const REGIONS_TYPE = [
  "locality",
  "sublocality",
  "postal_code",
  "country",
  "administrative_area_level_1",
  "administrative_area_level_2",
];

const BASIC_DATA_FIELDS = [
  "address_components",
  "adr_address",
  "formatted_address",
  "place_id",
  "id",
  "geometry",

];
@Component({
  components: {
   
  },
  methods: {
  },
})
export default class AutoComplete extends Vue {
  @Prop({ required: true })
  id!: string;

  @Prop({ required: true })
  value!: string;

  // @Prop({ required: true })
  // rules!: [];

  @PropSync("value", {required: false, default:""})
  valueSynced!: string;

  @Prop({})
  classname!: string;

  @Prop({})
  label!: string;
  
  @Prop({default: null})
  placeId!: string;

  @Prop({default: null})
  lat!: number;

  @Prop({default: null})
  lng!: number;
  
  @Prop({ default: 'Start typing' })
  placeholder!: string;

  @Prop({ default: false })
  disabled!: boolean;

  @Prop({ default: ()=>[] })
  types!: string[];

  @Prop({  default: function() {
              return BASIC_DATA_FIELDS;
            }, })
  fields!: [];

  @Prop({ default: null })
  country!: string | string[] | null;
  // country!: [string, []];

  @Prop({  default: false})
  enableGeolocation!: boolean;

  @Prop({ default: null })
  geolocationOptions!: Object;

  

  autocompleteRules = [
      (v: string, place: any, disabled:boolean, placeId: string, lat:number, lng:number) => {
        function isEmptyOrSpaces(str: string){
    return str === null || str.match(/^ *$/) !== null;
}
        if (disabled||((placeId)&&(lat)&&(lng))||(placeId!=null&&placeId!=""&&lat!=null&&lat!=0&&lng!=null&&lng!=0))
        {return true}
        if (!disabled&&!isEmptyOrSpaces(v)&&(place)){
          
          if (!!place.geometry) {
            
            return true;
          }

          else {
            return 'Address was invalid/not found'
          }
        }
        else {
          return 'Address was invalid/not found'
        }
      },
      (v: string, disabled: boolean, placeId: string, lat:number, lng:number) => {
        if (disabled||!!v||(placeId!=null&&lat!=null&&lng!=null)){
          return true;
        } else {
          return 'Address is required'
        }
      },
  ];
      // (v: string) => {
      //   let place = this.autocomplete.getPlace()
      //   if (v&&!place.geometry){
      //     return 'Address was invalid/not found'
      //   } else {
      //     return true;
      //   }
      // },
      // (v: string) => {
      //   if (!!v){
      //     return 'Address is required'
      //   } else {
      //     return true;
      //   }
      // },

      /**
       * The Autocomplete object.
       *
       * @type {Autocomplete}
       * @link https://developers.google.com/maps/documentation/javascript/reference#Autocomplete
       */
      autocomplete: google.maps.places.Autocomplete = new google.maps.places.Autocomplete(
                document.getElementById(this.id) as HTMLInputElement,
                // document.getElementById(this.id) as HTMLInputElement,
                {
                  types: this.types ? this.types : undefined,
            componentRestrictions: this.country ? {
              country: this.country
            } : undefined,
                }
            );;
      

      /**
       * Autocomplete input text
       * @type {string}
       */
      autocompleteText: string = "";

      geolocation!: {
          /**
           * Google Geocoder Objet
           * @type {Geocoder}
           * @link https://developers.google.com/maps/documentation/javascript/reference#Geocoder
           */
          geocoder: any,

          /**
           * Filled after geolocate result
           * @type {Coordinates}
           * @link https://developer.mozilla.org/en-US/docs/Web/API/Coordinates
           */
          loc: any,

          /**
           * Filled after geolocate result
           * @type {Position}
           * @link https://developer.mozilla.org/en-US/docs/Web/API/Position
           */
          position: any,
      };
            
  @Watch("valueSynced")
    onPropertyChanged3(newVal: any, oldVal: any) {
    this.$emit('inputChange', { newVal, oldVal }, this.id);
    }

  @Watch("country")
  onPropertyChanged4() {
  // onPropertyChanged4(newVal: any, oldVal: any) {
    this.autocomplete!.setComponentRestrictions({
    country: this.country === null ? [] : this.country
    });
}
mounted() {
  // let e: google.maps.places.Autocomplete
    const options: any = {};

          if (this.types) {
            options.types = this.types;
          }

          if (this.country) {
            options.componentRestrictions = {
              country: this.country
            };
          }

          this.autocomplete = new google.maps.places.Autocomplete(
                document.getElementById(this.id) as HTMLInputElement,
                options
            );

          this.autocomplete.setFields(this.fields);

          this.autocomplete.addListener('place_changed', this.onPlaceChanged);
          

}

              onPlaceChanged() {
                let place = this.autocomplete!.getPlace();

                if (!place.geometry) {
                  // User entered the name of a Place that was not suggested and
                  // pressed the Enter key, or the Place Details request failed.
                  this.autocompleteText = (document.getElementById(this.id) as HTMLInputElement)!.value;
                  this.value = (document.getElementById(this.id) as HTMLInputElement)!.value;
                  // this.$emit('noResult', this.autocompleteText);
                  this.$emit('no-results-found', this.autocompleteText);
                  return;
                }

                if (place.address_components !== undefined) {
                    // return returnData object and PlaceResult object
                    this.$emit('placechanged', this.formatResult(place), place, this.id);

                    // update autocompleteText then emit change event
                    this.autocompleteText = (document.getElementById(this.id) as HTMLInputElement)!.value;
                    console.log(this.autocompleteText)
                    this.onChange();
                }
              }

            /**
             * When the input gets focus
             */
            onFocus() {
              this.biasAutocompleteLocation();
              this.$emit('focus');
            }

            /**
             * When the input loses focus
             */
            onBlur() {
              this.$emit('blur');
            }

            /**
             * When the input got changed
             */
            onChange() {
              this.autocompleteText = (document.getElementById(this.id) as HTMLInputElement)!.value;
                  this.value = (document.getElementById(this.id) as HTMLInputElement)!.value;
              // this.autocompleteText = (document.getElementById(this.id) as HTMLInputElement)!.value;
              this.$emit('change', this.autocompleteText);
            }

            /**
             * When a key gets pressed
             * @param  {Event} event A keypress event
             */
            onKeyPress(event: Event) {
              this.$emit('keypress', event);
            }

            /**
             * When a keyup occurs
             * @param  {Event} event A keyup event
             */
            onKeyUp(event: Event) {
              this.$emit('keyup', event);
            }

            /**
             * Clear the input
             */
            clear() {
              this.autocompleteText = ''
            }

            /**
             * Focus the input
             */
            focus() {
              (this.$refs.autocomplete as Vue & { focus: () => any }).focus();
            }

            /**
             * Blur the input
             */
            blur() {
              (this.$refs.autocomplete as Vue & { blur: () => any }).blur();
            }

            /**
             * Update the value of the input
             * @param  {string} value
             */
            update (value: string) {
              this.autocompleteText = value
              console.log(this.autocompleteText)
            }

            /**
             * Update the coordinates of the input
             * @param  {Coordinates} value
             */
            updateCoordinates (value: any) {
                if (!value && !(value.lat || value.lng)) return;
                if (!this.geolocation.geocoder) this.geolocation.geocoder = new google.maps.Geocoder();
                this.geolocation.geocoder.geocode({'location': value}, (results: any, status: any) => {
                    if (status === 'OK') {
                        results = this.filterGeocodeResultTypes(results);
                        if (results[0]) {
                            this.$emit('placechanged', this.formatResult(results[0]), results[0], this.id);
                            this.update(results[0].formatted_address);
                        } else {
                            this.$emit('error', 'no result for provided coordinates');
                        }
                    } else {
                        this.$emit('error', 'error getting address from coords');
                    }
                })
            }

            /**
             * Update location based on navigator geolocation
             */
            geolocate () {
                this.updateGeolocation ((geolocation: any) => {
                // this.updateGeolocation ((geolocation: any, position: any) => {
                    this.updateCoordinates(geolocation)
                })
            }

            /**
             * Update internal location from navigator geolocation
             * @param  {Function} (geolocation, position)
             */
            updateGeolocation (callback: any = null) {
                if (navigator.geolocation) {
                    let options = {};
                    if(this.geolocationOptions) Object.assign(options, this.geolocationOptions);
                    navigator.geolocation.getCurrentPosition(position => {
                        // interface Geolocation {
                        //     lat: number,
                        //     lng: number
                        // }

                        // var geolocation1 = {} as Geolocation;
                        // geolocation1.lat = position.coords.latitude;
                        // geolocation1.lng = position.coords.longitude;
                        let geolocation = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        };
                        this.geolocation.loc = geolocation;
                        this.geolocation.position = position;

                        if (callback) callback(geolocation, position);
                    }, (err: any) => {
                        this.$emit('error', 'Cannot get Coordinates from navigator', err);
                    },
                    options);
                }
            }


            // Bias the autocomplete object to the user's geographical location,
            // as supplied by the browser's 'navigator.geolocation' object.
            biasAutocompleteLocation () {
                if (this.enableGeolocation) {
                    this.updateGeolocation((geolocation: any, position: { coords: { accuracy: any; }; }) => {
                        let circle = new google.maps.Circle({
                            center: geolocation,
                            radius: position.coords.accuracy
                        });
                        this.autocomplete!.setBounds(circle.getBounds()!);
                    })
                }
            }

            /**
             * Format result from Geo google APIs
             * @param place
             * @returns {{formatted output}}
             */
            formatResult (place: any) {
                let returnData: any = {};
                for (let i = 0; i < place.address_components.length; i++) {
                    let addressType = place.address_components[i].types[0];

                    if (ADDRESS_COMPONENTS[addressType]) {
                        let val = place.address_components[i][ADDRESS_COMPONENTS[addressType]];
                        returnData[addressType] = val;
                    }
                }

                returnData['latitude'] = place.geometry.location.lat();
                returnData['longitude'] = place.geometry.location.lng();
                return returnData
            }

            /**
             * Extract configured types out of raw result as
             * Geocode API does not allow to do it
             * @param results
             * @returns {GeocoderResult}
             * @link https://developers.google.com/maps/documentation/javascript/reference#GeocoderResult
             */
            filterGeocodeResultTypes (results: any) {
                if (!results || !this.types) return results;
                let output = [];
                let types = this.types;
                if (types.includes('(cities)')) types = types.concat(CITIES_TYPE);
                if (types.includes('(regions)')) types = types.concat(REGIONS_TYPE);

                for (let r of results) {
                    for (let t of r.types) {
                        if (types.includes(t)) {
                            output.push(r);
                            break;
                        }
                    }
                }
                return output;
            }
}
