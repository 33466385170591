
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';


import { langs } from '@/constants';
import { Hour } from '@/entities/restaurant/Hour';

@Component
export default class RestaurantHoursList extends Vue {
  @Prop({ required: true })
  value!: Hour[];
  @Prop({ required: true })
  noHours!: boolean;

  dialog = false;

  selected: Hour[] = [];
  search: string = "";

  langs = langs;
  lang = langs[0].abbr;

  showHours = true;  

  $refs!: {
    formHours: HTMLFormElement
  }
  valid = false;
  // disabledHours = false;
  openRules = [
      // (v: string, item:Hour) => {
      //   if(this.disabledHours){
      //     return true;
      //   }
      //   if ((/^\d+$/.test(v.substring(0,1)) &&
      //       /^\d+$/.test(v.substring(3,4)) &&
      //       v[2] == ':' && v.length==5) || (!!item&&!!item.alwaysOpen) || (!!item&&!!item!.closed)  
      //   ) {
      //     return true;
      //   } else {
      //     return 'Open is required'
      //   }
      // },
  ];
  closeRules = [

      // (v: string, item:Hour) => {
      //   if(this.disabledHours){
      //     return true;
      //   }
      //   if ((/^\d+$/.test(v.substring(0,1)) &&
      //         /^\d+$/.test(v.substring(3,4)) &&
      //         v[2] == ':' && v.length==5) || (!!item&&!!item.alwaysOpen) || (!!item&&!!item!.closed)  
      //     ) {
      //       return true;
      //     }else {
      //       return 'Close is required'
      //     }

      // },
  ];


  time = null;
  menuClose = false;
  menuOpen = false;
  editedIndex = -1;
  editedHours = {
      id: 0,
      day: '',
      open: '',
      close: '',
      alwaysOpen: false,
      closed: false,
  } as Hour;
  defaultHours = {
      id: 0,
      day: '',
      open: '',
      close: '',
      alwaysOpen: false,
      closed: false,
  } as Hour;
  // hours=[
  //   {
  //     id: 1,
  //     day: 'Lundi',
  //     open: '',
  //     close: '',
  //     alwaysOpen: false,
  //     closed: false,
  //   },
  //   {
  //     id: 2,
  //     day: 'Mardi',
  //     open: '',
  //     close: '',
  //     alwaysOpen: false,
  //     closed: false,
  //   },
  //   {
  //     id: 3,
  //     day: 'Mercredi',
  //     open: '',
  //     close: '',
  //     alwaysOpen: false,
  //     closed: false,
  //   },
  //   {
  //     id: 4,
  //     day: 'Jeudi',
  //     open: '',
  //     close: '',
  //     alwaysOpen: false,
  //     closed: false,
  //   },
  //   {
  //     id: 5,
  //     day: 'Vendredi',
  //     open: '',
  //     close: '',
  //     alwaysOpen: false,
  //     closed: false,
  //   },
  //   {
  //     id: 6,
  //     day: 'Samedi',
  //     open: '',
  //     close: '',
  //     alwaysOpen: false,
  //     closed: false,
  //   },
  //   {
  //     id: 7,
  //     day: 'Dimanche',
  //     open: '',
  //     close: '',
  //     alwaysOpen: false,
  //     closed: false,
  //   },
  // ] as Hour[];

  headers: DataTableHeader[] = [
    {
      text: "Day of week",
      value: "day",
    },
    {
      text: "Open",
      value: "open",
      // width: "160"
    },
    {
      text: "Close",
      value: "close",
      // width: "160"
    },
    {
      text: "Open 24h",
      value: "alwaysOpen",
    },
    {
      text: "Closes",
      value: "closed",
    },
  ];

  get heures() {
    return this.value;
  }
  set heures(value1) {
    this.value=value1;
  }

  get disabledHours() {
    return this.noHours;
  }
  set disabledHours(value1) {
    this.$emit('update:noHours', value1);
  }

  editItem (item: any, mode : string) {
    if (mode=="open") {
      this.editedIndex = this.value.indexOf(item);
      this.editedHours = Object.assign({}, item);
      this.menuOpen = true;
    }
    else if (mode=="close") {
      this.editedIndex = this.value.indexOf(item);
      this.editedHours = Object.assign({}, item);
      
      this.menuClose = true;
    }
    // console.log(this.heures)
    }
  @Watch('menuClose')
  onPropertyChanged(value: string) {
    value || this.close();
  }
  @Watch('menuOpen')
  onPropertyChanged2(value: string) {
    value || this.close();
  }
  close () {
      this.menuClose = false;
      this.menuOpen = false;
      this.$nextTick(() => {
        this.editedHours = Object.assign({}, this.defaultHours);
        this.editedIndex = -1;
      })
  }
  save () {
    
      if (this.editedIndex > -1) {
        Object.assign(this.heures[this.editedIndex], this.editedHours);
        // console.log(this.editedHours);
      } else {
        this.heures.push(this.editedHours);
        // console.log(this.editedHours);
      }
      this.close();
  }

}
