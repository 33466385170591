
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';

import FoodDialog from '@/components/dialogs/FoodDialog.vue';

import { langs } from '@/constants';
import { Food } from '@/entities/restaurant/Food';

@Component({
  components: {
    FoodDialog
  }
})
export default class RestaurantFoodList extends Vue {
  @Prop({ required: true })
  value!: Food[];

  dialog = false;

  selected: Food[] = [];
  search: string = "";

  langs = langs;
  lang = langs[0].abbr;

  imgAlert = false;
  headers: DataTableHeader[] = [
    {
      text: "Name",
      value: "name",
    },
    {
      text: "Price",
      value: "price",
    },
    {
      text: "Category",
      value: "category"
    },
    {
      text: "Type",
      value: "type",
    }
  ];

  get food() {
    return this.value;
  }
  set food(value) {
    this.value=value;
  }
  get foodIndexed() {
    //  interface indexedItem {
    //   food: Food;
    //   index: number;
    // }
    // let indexedFood: indexedItem[] = [];
    // indexedFood = this.food.map((item, index) => ({
    //     food: item,
    //     index: index,
      
    //   }));

    // this.food.forEach((food) => indexedFood.push(food))
    let indexed = this.food.map((item, index) => ({
        indexer: index,

        ...item,

      
      }));
      return indexed;
  }
  get foodForm() {
    let foodlist = this.$refs.foodDialog as Vue & { validate: () => boolean };
    return foodlist.$refs.foodForm as Vue & { validate: () => boolean, reset: () => boolean };
  }
  get foodDial() {
    let foodlist = this.$refs.foodDialog as Vue & { validate: () => boolean, reset: () => {}  };
    return foodlist;
  }

  openDialog()
  {
    this.dialog = true;
    
  }

  name(food: Food) {
    return food.name[this.lang];
  }
  // validateForm(food: Food) {
  //   if (!(food.image!)) {
  //     this.imgAlert=true;
  //   }
  //   var restoValid = (this.foodForm.validate()&&!!(food.image!))
  //   return restoValid;
  // }

  addFood(food: Food) {
    // if(this.validateForm(food))
    // {
      
      this.food.push(food);
// this.foodForm.reset();
    
    // }
    // else{
    //   //food = {} as Food;
    //   this.dialog=true;
    //   // this.imgAlert=true;
    // }
  }
  // refresh() {
  // }
  removeSelected() {
    this.selected.forEach(food => {
      // let indexedfood = this.foodIndexed;
      // // let converted = food as typeof indexedfood[number];
      // let {indexer,...foodObj} = food as typeof indexedfood[0];
      
      // //To avoid error
      // indexer;
      // let converted:Food = {...foodObj}
      
      // if (this.food.(x => x.name==converted.name&&x.category==converted.category&&x.price==converted.price&&x.type==converted.type&&x.image==converted.image)) {
      if (this.food.indexOf(food)) {
        this.food.splice(this.food.indexOf(food), 1);
      }
    });

    this.selected = [];
  }
}
